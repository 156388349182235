import axios from "axios";

export default class QnAService {
  getList(salonID = '') {
    return axios
      .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/qna?salon_id=${salonID}`)
      .then((res) => res);
  }

  postReply(formData) {
    return axios
      .post(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/qna`, formData)
      .then((res) => res)
      .catch((e) => e.response);
  }
}
