import { ref } from "vue";
import QnAService from "@/service/QnAService";
import { useToast } from "primevue/usetoast";

export function useQnA() {
  const toast = useToast();
  const qnaService = new QnAService();
  const data = ref([]);

  const getList = async (salonID) => {
    const res = await qnaService.getList(salonID);
    data.value = res.data.data.qna;
  };

  const postReply = async (formData) => {
    const res = await qnaService.postReply(formData);

    if (res.status !== 200) {
      toast.add({
        severity: "error",
        closable: true,
        summary: "Error",
        detail: res.data.message,
        life: 5000,
      });
      return;
    }

    toast.add({
      severity: "success",
      closable: true,
      summary: "Success",
      detail: res.data.message,
      life: 5000,
    });

    return res.status;
  };

  return { data, getList, postReply };
}
